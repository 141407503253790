<template>
    <div>
        <v-row v-if="!$parent.$parent.get_permission('read')">
            <v-col>
                <AccessMessage />
            </v-col>
        </v-row>
        <v-row v-else>
            <!-- menu superior -->
            <v-col cols="12">
                <v-btn v-if="$parent.$parent.get_permission('edit')" :to="{ name: 'dashboard_sias_edit', params: $router.params }"
                    class="ma-1" large solo depressed color="secondary">
                    <v-icon class="mr-2">edit</v-icon>
                    <span class="subtitle-2">Editar</span>
                </v-btn>
            </v-col>
            <!-- general -->
            <v-col cols="12" sm="4">
                <DashCard title="General">
                    <v-row>
                        <v-col cols="12">
                            <v-label>Código externo de la solicitud</v-label>
                            <v-text-field hide-details dense solo outlined flat readonly v-model="item.cod_externo" />
                        </v-col>
                        <v-col cols="12">
                            <v-label>Fecha de la solicitud</v-label>
                            <v-text-field hide-details dense solo outlined flat readonly :value="item.fecha_solicitud | moment('L')" prepend-inner-icon="event" />
                        </v-col>
                        <v-col cols="12">
                            <v-label>Estado</v-label>
                            <v-flex>
                                <v-chip dark large label :color="item.estado | color_estado('sia')">
                                    <v-icon class="pr-2">flag</v-icon>
                                    <span>{{ item.estado | estado('sia') }}</span>
                                </v-chip>
                            </v-flex>
                        </v-col>
                    </v-row>
                </DashCard>
            </v-col>
            <v-col cols="12" sm="8" lg="4">
                <DashCard title="Detalles">
                    <v-row>
                        <v-col cols="12">
                            <v-label>Tipo de Obra</v-label>
                            <v-textarea hide-details dense solo outlined flat readonly v-model="item.descripcion_tipo_obra" />
                        </v-col> 
                        <v-col cols="12">
                            <v-label>Observaciones</v-label>
                            <v-textarea hide-details dense solo outlined flat readonly v-model="item.observacion" />
                        </v-col> 
                    </v-row>
                </DashCard>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4">
                <DashCard title="Responsables">
                    <v-col cols="12">
                        <v-label>Encargado/a</v-label>
                        <PersonaListItem v-model="item.persona_autor" />
                    </v-col>
                    <v-col cols="12">
                        <v-label>Jefe del encargado/a</v-label>
                        <PersonaListItem v-model="item.jefe_autor" />
                    </v-col>
                </DashCard>
            </v-col>
            <!-- solicitante -->
            <v-col cols="12" sm="6">
                <DashCard title="Solicitante y Responsable">
                    <v-row>
                        <v-col cols="12">
                            <v-label>Empresa</v-label>
                            <v-text-field hide-details dense solo outlined readonly flat v-model="empresa.nombre" prepend-inner-icon="assignment_ind" />
                        </v-col>
                        <v-col cols="12">
                            <v-label>Persona/Representante</v-label>
                            <v-text-field hide-details dense solo outlined readonly flat v-model="item.persona_empresa_solicitante" prepend-inner-icon="face" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-label>Area responsable</v-label>
                            <v-text-field hide-details dense solo outlined readonly flat v-model="area.nombre" prepend-inner-icon="room" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-label>Gerencia responsable</v-label>
                            <v-text-field hide-details dense solo outlined readonly flat v-model="gerencia.nombre" prepend-inner-icon="room" />
                        </v-col>
                    </v-row>
                </DashCard>
            </v-col>
            <!-- cierre -->
            <v-col cols="12" sm="6">
                <DashCard title="Cierre">
                    <v-row>
                        <v-col cols="12">
                            <v-label>Fecha establecida para el cierre</v-label>
                            <v-text-field hide-details dense solo outlined readonly flat :value="item.fecha_cierre | moment('L')" prepend-inner-icon="event" />
                        </v-col>
                        <v-col cols="12">
                            <v-label>Responsable del cierre</v-label>
                            <PersonaListItem v-model="persona_cierre" />
                        </v-col>
                    </v-row>
                </DashCard>
            </v-col>
            <!--- georeferencia -->
            <v-col cols="12">
                <BoxGeoreferencia ref="geo" v-model="item.georeferencia" readonly />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import MapCard from '@/dash/components/MapCard'
import BoxGeoreferencia from '@/dash/form_boxes/box_georeferencia'
import DashCard from '@/shared/components/DashCard'
import PersonaListItem from '@/dash/components/PersonaListItem'
import AccessMessage from '@/dash/components/AccessMessage'
import { axiosHelper } from '@/helpers'
import moment from 'moment'

export default {
    components: {
        DashCard,
        MapCard,
        PersonaListItem,
        BoxGeoreferencia,
        AccessMessage
    },
    data: () => ({
        capas: [],
        item: {},
        title: {
            text: "Solicitud",
            icon: "inbox"
        }
    }),
    computed: {
        area () {
            return this.item.area_responsable || {};
        },
        empresa () {
            return this.item.empresa || {};
        },
        gerencia () {
            return this.item.gerencia_responsable || {};
        },
        persona_cierre () {
            return this.item.responsable_cierre || {};
        },
        persona_autor () {
            return this.item.persona_autor || {};
        },
        jefe_autor () {
            return this.item.jefe_autor || {};
        }
    },
    mounted() {
        this.$emit('title', this.title);

        if(!this.$parent.$parent.get_permission('read')) return;

        axiosHelper.get('sias/' + this.$route.params.id)
            .then(response => {
                this.item = response.data.item;
                this.capas = response.data.capas;
                //this.$refs.geo.load_kmz_list(this.capas.map(x => x.fuente.archivo));
                this.title.text = "Solicitud " + (this.item.id) + " :: " + (this.item.cod_externo || "Sin Código");
            });
    }
}
</script>